<template>
  <div id="request">
    <RequestInfo :request="request" :view="view"/>
    <div v-if="!isArchived">
      <b-button variant="success" v-b-modal="`request-${request.requestId}`"
                @click="addAccountApproveRequest(request)" class="ml-3 mt-2">
        <b-icon-check/> {{ $t('comp.request.createAccountApproveRequest.label') }}
      </b-button>
      <b-button variant="warning" v-b-modal="`request-${request.requestId}`"
                @click="addFollowUpRequest(request)" class="ml-3 mt-2">
        <b-icon-question/> {{ $t('comp.request.addFollowUpRequest.label') }}
      </b-button>
      <b-button variant="danger" v-b-modal="`request-${request.requestId}`"
                @click="declineRequest(request)" class="ml-3 mt-2">
        <b-icon-x/> {{ $t('comp.request.declineRequest.label') }}
      </b-button>

      <b-modal :id="`request-${request.requestId}`" :title="modalHeader"
               size="xl" @hidden="resetForms">
        <div>
          <b-form v-if="accountForm.showForm">
            <AccountForm :account="accountForm" :action="actionAccount" :view="view"/>
          </b-form>
          <b-form v-if="requestForm.showForm">
            <RequestForm :request="requestForm" :action="actionRequest" :view="view"/>
          </b-form>
        </div>
        <template #modal-footer="{ cancel }">
          <b-button variant="dark" @click="cancel()">
            {{ $t('cancel.label') }}
          </b-button>
          <b-button @click="onSubmit" variant="primary">
            <b-icon-arrow-up shift-v="-1" class="mr-1"/> {{ $t('submit.label') }}
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { i18nMixin } from '@/mixins/i18n.mixin'
import RequestInfo from '@/components/generic/info/RequestInfo'
import AccountForm from '@/components/generic/form/AccountForm'
import RequestForm from '@/components/generic/form/RequestForm'

export default {
  name: 'Request',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    RequestForm,
    AccountForm,
    RequestInfo
  },
  props: {
    request: {
      type: Object,
      required: true
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      actionRequest: '',
      actionAccount: '',
      requestForm: {
        form: null,
        showForm: false,
        emptyForm: {
          requestId: '',
          forUser: null,
          forProject: null,
          message: null,
          state: 'pending'
        }
      },
      accountForm: {
        form: null,
        showForm: false,
        showDateTimeDecadeNav: true,
        emptyForm: {
          username: '',
          homeDirectory: '',
          shell: '/bin/bash',
          uid: '',
          project: this.forProject,
          user: this.forUser,
          publicKeys: [],
          state: 'active',
          createdAt: '',
          validUntil: null
        }
      }
    }
  },
  beforeMount () {
  },
  computed: {
    modalHeader () {
      if (this.actionAccount === 'add' && this.actionRequest === 'approve') {
        return this.$i18n.t('comp.request.createAccountApproveRequest.header',
          { request: this.requestForm.form.requestId })
      } else if (this.actionRequest === 'addFollowUp') {
        return this.$i18n.t('comp.request.addFollowUpRequest.header',
          { request: this.requestForm.form.requestId })
      } else if (this.actionRequest === 'decline') {
        return this.$i18n.t('comp.request.declineRequest.header',
          { request: this.requestForm.form.requestId })
      } else {
        return ''
      }
    },
    isArchived () {
      return this.request.state !== 'pending'
    }
  },
  methods: {
    resetForms () {
      this.actionAccount = ''
      this.actionRequest = ''
      this.accountForm.form = null
      this.accountForm.showForm = false
      this.requestForm.form = null
      this.requestForm.showForm = false
    },
    addAccount (project, user) {
      this.accountForm.form = _.cloneDeep(this.accountForm.emptyForm)
      this.accountForm.form.project = project
      this.accountForm.form.user = user
      this.accountForm.showForm = true
    },
    approveRequest (request) {
      this.requestForm.form = _.cloneDeep(request)
      this.requestForm.form.state = 'approved'
      this.requestForm.showForm = true
    },
    addAccountApproveRequest (request) {
      this.actionAccount = 'add'
      this.actionRequest = 'approve'
      this.approveRequest(request)
      this.addAccount(request.forProject, request.forUser)
    },
    addFollowUpRequest (request) {
      this.actionRequest = 'addFollowUp'
      this.requestForm.form = _.cloneDeep(request)
      this.requestForm.form.state = 'pending-follow-up'
      this.requestForm.showForm = true
    },
    declineRequest (request) {
      this.actionRequest = 'decline'
      this.requestForm.form = _.cloneDeep(request)
      this.requestForm.form.state = 'declined'
      this.requestForm.showForm = true
    },
    onSubmit (evt) {
      evt.preventDefault()
      if (this.actionAccount === 'add' && this.actionRequest === 'approve') {
        this.$store.dispatch({ type: 'manager/addAccount', account: this.accountForm.form }).then(
          response => {
            this.makeToast(
              this.$i18n.t('created.text', { id: response.username, code: response.code }),
              this.$i18n.t('comp.requests.accounts.created.title'),
              'success'
            )
            this.requestForm.form.account = response.username
            this.$store.dispatch({ type: 'manager/updateRequest', request: this.requestForm.form }).then(
              response => {
                this.makeToast(
                  this.$i18n.t('updated.text', { id: this.requestForm.form.requestId, code: response.code }),
                  this.$i18n.t('comp.requests.approved.title'),
                  'success'
                )
                this.$bvModal.hide(`request-${this.requestForm.form.requestId}`)
              }
            ).catch(
              error => this.makeToast(
                this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.requestForm.form.requestId }),
                this.$i18n.t('result.error.title'),
                'danger'
              )
            )
          }
        ).catch(
          error => this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: error.message }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        )
      } else if (this.actionRequest === 'addFollowUp') {
        this.$store.dispatch({ type: 'manager/updateRequest', request: this.requestForm.form }).then(
          response => {
            this.makeToast(
              this.$i18n.t('updated.text', { id: this.requestForm.form.requestId, code: response.code }),
              this.$i18n.t('comp.requests.addedFollowUp.title'),
              'success'
            )
            this.$bvModal.hide(`request-${this.requestForm.form.requestId}`)
          }
        ).catch(
          error => this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.requestForm.form.requestId }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        )
      } else if (this.actionRequest === 'decline') {
        this.$store.dispatch({ type: 'manager/updateRequest', request: this.requestForm.form }).then(
          response => {
            this.makeToast(
              this.$i18n.t('updated.text', { id: this.requestForm.form.requestId, code: response.code }),
              this.$i18n.t('comp.requests.declined.title'),
              'success'
            )
            this.$bvModal.hide(`request-${this.requestForm.form.requestId}`)
          }
        ).catch(
          error => this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.requestForm.form.requestId }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        )
      }
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>

<style scoped>
</style>
