import { render, staticRenderFns } from "./AccountForm.vue?vue&type=template&id=1ca53d54&scoped=true"
import script from "./AccountForm.vue?vue&type=script&lang=js"
export * from "./AccountForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ca53d54",
  null
  
)

export default component.exports